.Template {
    padding-top: 20px;
    max-height: 100vh;
}
.title {
    width: 90vh;
    margin-left: auto;
    margin-right: auto;

    padding-bottom: 20px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #6c567b;
}